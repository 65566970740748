import React, { createContext, useContext, useState, useEffect } from 'react';

// Create a context with default value "light"
const CustomThemeContext = createContext({
    themeMode: 'light',
    toggleThemeMode: () => {},
});

export const CustomThemeProvider = ({ children }) => {
    // Load the theme from localStorage or default to "light"
    const [themeMode, setThemeMode] = useState(localStorage.getItem('themeMode') || 'light');

    // Save the theme to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem('themeMode', themeMode);
    }, [themeMode]);

    // Toggle between "dark" and "light" themes
    const toggleThemeMode = () => {
        console.log('#### toggleThemeMode triggered!')
        setThemeMode((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
    };

    return (
        <CustomThemeContext.Provider value={{ themeMode: themeMode, toggleThemeMode: toggleThemeMode }}>
            {children}
        </CustomThemeContext.Provider>
    );
};

// Custom hook to use the CustomThemeContext
export const useCustomTheme = () => useContext(CustomThemeContext);


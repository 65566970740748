import React, {useEffect, useRef} from 'react';
import { styled } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { Box, Chip, Stack } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import {useCustomTheme} from "./CustomThemeContext";

const HtmlTooltip = styled(({ className, themeMode, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, themeMode }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: themeMode === 'dark' ? '#2a2f35' : '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #dadde9',
  },
}));

const CardTooltip = (props) => {
  const { themeMode } = useCustomTheme();
  const [open, setOpen] = React.useState(false);
  const tooltipRef = useRef(null);

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener('scroll', handleOutsideClick);
    document.addEventListener('click', handleOutsideClick);
  }, []);

  return (
    <div ref={tooltipRef}>
      <HtmlTooltip
        themeMode={themeMode}
        PopperProps={{
          disablePortal: true,
        }}
        open={open}
        title={
          <React.Fragment>
            <Stack
              direction={{ xs: 'column', sm: 'column' }}
              spacing={1}
              sx={{
                flexWrap: 'wrap',
              }}
            >
              {props.parkraum.eplaetze && (
                <Chip id="dark-mode-bg"
                  label={`Freie Ladeplätze: ${props.parkraum.eplaetze}`}
                  size="small"
                />
              )}
              {props.parkraum.bplaetze && (
                <Chip id="dark-mode-bg"
                  label={`Freie Behindertenparkplätze: ${props.parkraum.bplaetze}`}
                  size="small"
                />
              )}
              {props.parkraum.max_hoehe && (
                <Chip id="dark-mode-bg"
                  label={`Einfahrtshöhe: ${props.parkraum.max_hoehe} m`}
                  size="small"
                />
              )}
              <Chip id="dark-mode-bg"
                label={`Betreiber: ${props.parkraum.bez_int}`}
                size="small"
              />
              <Chip id="dark-mode-bg"
                label={`${props.parkraum.stand} (${props.parkraum.zeit_kurz})`}
                size="small"
              />
            </Stack>

            <Box sx={{ marginTop: '20px' }}>
              {' '}
              {/* Add more space above the bottom chips */}
              <Stack direction={{ xs: 'column', sm: 'column' }} spacing={1}>
                <Chip id="dark-mode"
                  label="Betreiberwebsite"
                  variant="outlined"
                  component="a"
                  href={props.parkraum.website}
                  target="_blank"
                  icon={<LaunchIcon />}
                  sx={{ flex: '1 0 100%' }}
                />
              </Stack>
            </Box>
          </React.Fragment>
        }
      >
        <IconButton
          id="dark-mode"
          onClick={handleTooltipOpen}
          disableRipple={true}
        >
          <InfoIcon  />
        </IconButton>
      </HtmlTooltip>
    </div>
  );
};

export default CardTooltip;

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Box,
} from '@mui/material';
import {
  Brightness5,
  LightMode,
  SettingsBrightness,
} from '@mui/icons-material';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import {useCustomTheme} from "./CustomThemeContext";

function DarkMode() {
  const { themeMode, toggleThemeMode } = useCustomTheme();
  /*const [theme2, setTheme] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      return savedTheme;
    }
    //const systemPrefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    const systemPrefersDark = false;
    return systemPrefersDark ? 'dark' : 'light';
  });*/

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (themeMode === 'dark') {
      document.body.classList.add('dark-mode');
      document.body.classList.remove('light-mode');
    } else if (themeMode === 'light') {
      document.body.classList.add('light-mode');
      document.body.classList.remove('dark-mode');
    } else {
      const systemPrefersDark = window.matchMedia(
        '(prefers-color-scheme: dark)',
      ).matches;
      if (systemPrefersDark) {
        document.body.classList.add('dark-mode');
        document.body.classList.remove('light-mode');
      } else {
        document.body.classList.add('light-mode');
        document.body.classList.remove('dark-mode');
      }
    }
  }, [themeMode]);

  const handleThemeChange = () => {
    toggleThemeMode();
  };

  return (
    <>
      <IconButton id="dark-mode" onClick={handleOpen}>
        <Brightness5 />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="theme-selection-dialog"
        id="dialog-title dark-mode"
      >
        {/*<DialogTitle id="dark-mode">Einstellungen</DialogTitle>*/}
        <DialogContent id="dark-mode">
          <Box
            id="dark-mode"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'left',
              alignItems: 'center',
            }}
          >
            <h4>Anzeige</h4>

            <ToggleButtonGroup
              exclusive
              value={themeMode}
              onChange={handleThemeChange}
              sx={{
                '& .MuiToggleButton-root': {
                  color: themeMode === 'dark' ? 'white' : 'black',
                  borderColor: themeMode === 'dark' ? '#515860' : '#f1f3f4',
                  '&.Mui-selected': {
                    backgroundColor: themeMode === 'dark' ? '#515860' : '#f1f3f4',
                    color: themeMode === 'dark' ? 'white' : 'black',
                  },
                },
              }}
            >
              <ToggleButton value="light">
                <LightMode />
                &nbsp;Hell
              </ToggleButton>
              <ToggleButton value="dark">
                <DarkModeIcon />
                &nbsp;Dunkel
              </ToggleButton>
              <ToggleButton value="system">
                <SettingsBrightness />
                &nbsp;System
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default DarkMode;

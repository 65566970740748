import React from 'react';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import DirectionsIcon from '@mui/icons-material/Directions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChargingStation } from '@fortawesome/free-solid-svg-icons';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { Box, CardContent, Chip, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardTooltip from './CardTooltip';
import { getMapLink } from './MapLink';
// import { Star, StarBorder } from '@mui/icons-material';
// import { useRecoilState } from 'recoil';
// import { parkraumAtom } from '../recoil/atom';

const ParkCardItem = (props) => {
  // const [parkraum, setParkraum] = useRecoilState(parkraumAtom);
  //
  // const handleClick = (elem) => {
  //   let updatedElement = { ...elem, favorit: !elem.favorit };
  //
  //   setParkraum([
  //     ...parkraum.filter(
  //       (obj) => !(obj.id === elem.id && obj.favorit === elem.favorit),
  //     ),
  //     updatedElement,
  //   ]);
  // };

  return (
    <Grid item xs={12} sm={6} md={6} sx={{ p: 0 }}>
      <Card
        id="dark-mode"
        elevation={4}
        sx={{
          display: 'flex',
          borderRadius: '12px',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Box
          sx={{
            padding: '10px 5px 5px 5px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ bgcolor: props.parkraum.color, fontSize: '19px' }}>
            {props.parkraum.plaetze}
          </Avatar>
        </Box>

        <Box
          sx={{
            paddingTop: '5px',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <CardContent sx={{ padding: '5px' }}>
            <Box id={'leftBox'}>
              <Stack direction="row" spacing={1}>
                <Chip
                  label={props.parkraum.art}
                  size="small"
                  sx={{
                    backgroundColor: '#ADD8E6',
                    color: '#000',
                    fontSize: '12px',
                  }}
                />
                {props.parkraum.eplaetze && (
                  <Chip
                    label={
                      <>
                        {/*{props.parkraum.eplaetze}{' '}*/}
                        <FontAwesomeIcon
                          icon={faChargingStation}
                          style={{ fontSize: '11px', paddingBottom: '0.6px' }}
                        />{' '}
                        {props.parkraum.eplaetze}
                      </>
                    }
                    size="small"
                    sx={{
                      backgroundColor: '#ADD8E6',
                      color: '#000',
                      fontSize: '12px',
                    }}
                  />
                )}
              </Stack>
              <Typography
                sx={{ fontSize: '16px', fontWeight: 500, lineHeight: '1.4' }}
                component="div"
                variant="title1"
              >
                {props.parkraum.bezeichnung_kurz}
                {/*({props.parkraum.id})*/}
              </Typography>
              <Typography
                id="dark-mode"
                sx={{ fontSize: '14px', lineHeight: '1.3' }}
                variant="subtitle1"
                color="text.secondary"
                component="div"
              >
                {props.parkraum.strasse}
                <br />
                {props.parkraum.ort}
              </Typography>

              {props.parkraum.stand === 'Daten nicht aktuell' && (
                <Typography
                  sx={{
                    fontSize: '12px',
                    color: '#F25C56',
                  }}
                  variant="subtitle2"
                  component="div"
                >
                  Daten nicht aktuell
                </Typography>
              )}
            </Box>
          </CardContent>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
            }}
          >
            <CardTooltip parkraum={props.parkraum} />
            {/*<IconButton*/}
            {/*  disableRipple={true}*/}
            {/*  onClick={() => handleClick(props.parkraum)}*/}
            {/*  aria-label="previous"*/}
            {/*>*/}
            {/*  {props.parkraum.favorit ? <Star /> : <StarBorder />}*/}
            {/*</IconButton>*/}
            <IconButton
              id="dark-mode"
              href={getMapLink(props.parkraum)}
              aria-label="DirectionsIcon"
            >
              <DirectionsIcon />
            </IconButton>
          </Box>
        </Box>
      </Card>
    </Grid>
  );
};

export default ParkCardItem;

import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { RecoilRoot } from 'recoil';
import BottomNavigationApp from './components/BottomNavigationApp';
import '@fontsource/public-sans';
import {CustomThemeProvider} from "./components/CustomThemeContext";

const App = () => {
  const storeVersion = localStorage.getItem('version');

  if (!storeVersion || storeVersion !== '1') {
    localStorage.clear();
    localStorage.setItem('version', 1);
    window.location.href = '/';
  }
  const theme = createTheme({
    status: {
      danger: '#e53e3e',
    },
    typography: {
      fontFamily: 'Public Sans',
    },
    palette: {
      primary: {
        main: '#2a2f35',
        darker: '#053e85',
      },
      neutral: {
        main: '#64748B',
        contrastText: '#fff',
      },
    },
  });

  return (
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <CustomThemeProvider>
          {/* <Header /> */}
          <BottomNavigationApp />
        </CustomThemeProvider>
      </ThemeProvider>
    </RecoilRoot>
  );
};

export default App;
